export const nithinpbdotcom = {
    name: 'nithinpbdotcom',
    options: (mode: string) => ({
        base: mode === 'dark' ? 'vs-dark' : 'vs',
        inherit: true,
        rules: [],
        colors: {
            "editor.foreground": mode === "dark" ? "#d4d4d4" : "#2d2d2d",
            "editor.background": mode === 'dark' ? '#121212' : "#ffffff",
            //"editorCursor.foreground": "#1e1e1e",
            //"editor.lineHighlightBackground": "#e7e7e7",
            "editorLineNumber.foreground": mode === 'dark' ? "#c6c6c6" : "#c3c3c3",
            //"editorLineNumber.activeForeground": "#1e1e1e",
            //"editor.selectionBackground": "#e7e7e7",
            //"editor.inactiveSelectionBackground": "#e7e7e7",
        },
    })
}


export const initialOptions = {
    fontFamily: 'Noto Sans Mono',
    fontSize: 14,
    glyphMargin: false,
    lineNumbersMinChars: 4,
    lineNumbers: function (lineNumber: any) {
        return `<span style="padding-left:8px">${lineNumber}</span>`;
    },
    lineHeight: 27,
    fontWeight: "600",
    scrollBeyondLastLine: false,
    // cursorSmoothCaretAnimation: "on" as "on",
    minimap: {
        enabled: false,
    },
    theme: nithinpbdotcom.name
}

export const defineTheme = (instance: any, themeMode: string) => {
    instance.editor.defineTheme(nithinpbdotcom.name, nithinpbdotcom.options(themeMode));
}


export const languages = [
    'plaintext',
    'abap',
    'apex',
    'azcli',
    'bat',
    'bicep',
    'cameligo',
    'clojure',
    'coffeescript',
    'c',
    'cpp',
    'csharp',
    'csp',
    "css",
    "cypher",
    "dart",
    "dockerfile",
    "ecl",
    "elixir",
    "flow9",
    "fsharp",
    "go",
    "graphql",
    "handlebars",
    "hcl",
    "html",
    "ini",
    "java",
    "javascript",
    "julia",
    "kotlin",
    "less",
    "lexon",
    "lua",
    "liquid",
    "m3",
    "markdown",
    "mips",
    "msdax",
    "mysql",
    "objective - c",
    "pascal",
    "pascaligo",
    "perl",
    "pgsql",
    "php",
    "pla",
    "postiats",
    "powerquery",
    "powershell",
    "proto",
    "pug",
    'python',
    "qsharp",
    "r",
    "razor",
    "redis",
    "redshift",
    "restructuredtext",
    "ruby",
    "rust",
    "sb",
    "scala",
    "scheme",
    "scss",
    "shell",
    "sol",
    "aes",
    "sparql",
    "sql",
    "st",
    "swift",
    "systemverilog",
    "verilog",
    "tcl",
    "twig",
    "typescript",
    "vb",
    "wgsl",
    "xml",
    "yaml",
    "json",

]
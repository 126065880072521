import { useState, useEffect } from "react";
import { Box, Typography, Divider, FormGroup, Stack, FormControlLabel, Collapse } from "@mui/material";
import { AutoAwesomeOutlined } from "@mui/icons-material";

import { Switch, Paper, TextField, Autocomplete, TextFieldAutocomplete } from "../../shared";
import { languages } from '../../components/Editor/variables'

export function AdditionalControl(props: any) {
    const [passwordControl, setPasswordControl] = useState(false)


    useEffect(() => {
        if (!passwordControl) {
            props.onPasswordChange(null)
        }
    }, [passwordControl])

    return (
        <Box mt={1} mb={1}>
            <Paper sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                <Box display={'flex'} alignItems={'center'} sx={{ p: 1, pl: 2 }} gap={1}>
                    <AutoAwesomeOutlined fontSize={'small'} />
                    <Typography sx={{ fontWeight: 600 }}>
                        Additional Options
                    </Typography>
                </Box>
                <Divider sx={{ width: '100%' }} />
                <FormGroup className="w-full">
                    <Stack direction={'column'} gap={0} mt={0.5} alignItems={'flex-start'} p={0.5} px={2}>
                        <Box className={'w-full'} mb={1}>
                            <Typography gutterBottom>
                                Language
                            </Typography>
                            <Autocomplete
                                width={'100%'}
                                disablePortal
                                onChange={(e: any, newValue: any) => props.onLanguageChange(newValue)}
                                size={'small'}
                                value={props.language}
                                options={languages}
                                sx={{ width: '100%' }}
                                renderInput={(params: any) => (
                                    <TextFieldAutocomplete
                                        {...params}
                                        required
                                        placeholder="Select language"
                                    />
                                )}
                            />
                        </Box>
                    </Stack>
                    <Divider />
                    <Stack direction={'column'} gap={0} mt={0.5} alignItems={'flex-start'} p={0.5} px={2}>
                        <Box>
                            <FormControlLabel
                                control={
                                    <Switch
                                        name="passwordProtect"
                                        checked={passwordControl}
                                        onChange={(e: any) => setPasswordControl(e.target.checked)}
                                    />
                                }
                                label="Protect note with password "
                            />
                        </Box>
                        <Collapse in={passwordControl} orientation={'vertical'} sx={{ width: '100%' }}>
                            <Box mt={1} mb={2} width={'100%'}>
                                <TextField
                                    fullWidth
                                    onChange={(e: any) => props.onPasswordChange(e.target.value)}
                                    label={'Note password'}
                                    sx={{ width: '100%' }}
                                    size="small" />
                            </Box>
                        </Collapse>
                        <Box>
                            <FormControlLabel
                                control={
                                    <Switch name="groupNote" disabled={true} />
                                }
                                label="Group this note"
                            />
                        </Box>
                        <Box>
                            <FormControlLabel
                                control={
                                    <Switch name="readOnly" disabled={true} />
                                }
                                label="Read only note"
                            />
                        </Box>
                    </Stack>
                </FormGroup>
            </Paper>
        </Box >
    )
}
import { Paper } from '@mui/material';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

export default function NotepadLayout() {
    const paramsData = useParams()

    const notepadId = paramsData?.id === 'notedrop' ? '' : paramsData?.id

    useEffect(() => {
        document.title = `NoteDrop ${notepadId ? "- " + notepadId : ""} | Nithin P B`

        return () => {
            document.title = 'ForceQuery | Nithin P B'
        }
    }, [paramsData.id])
    return (
        <Paper className='w-full h-full' elevation={0}>
            <Outlet />
        </Paper>)
}
import './index.scss'

export default function NotFound() {
    return (
        <div className={'NotFound-wrapper'}>
            <div>
                <span>404</span>
                Oops! You just can't do that.
            </div>
        </div>
    )
}
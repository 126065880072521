import { useMutation } from 'react-query'
import { useResponseParser, useAxios } from '..'
import { apiEndPoint } from '../../constants/apiEndPoints'

export default function useNotepad() {
    const { axiosPublic } = useAxios()
    const parseResponse = useResponseParser()

    const useGetNote = () => useMutation((params: any) => axiosPublic.post(`${apiEndPoint.GET_NOTE}${params.id}`, { password: params.password }).then(parseResponse))
    const useWriteNote = () => useMutation((params: any) => axiosPublic.post(apiEndPoint.WRITE_NOTE, params).then(parseResponse))

    return { useGetNote, useWriteNote }
}
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';

import { ThemeContext } from "../contexts"


export function ThemeProvider({ children }: IGlobalProvider) {
    // context and initialization goes here
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [mode, setMode] = useState(prefersDarkMode ? 'dark' : 'light')

    const theme = createTheme({
        palette: {
            mode: mode === 'dark' ? 'dark' : 'light',
            text: {
                primary: mode === 'dark' ? "#fff" : "#132F4C",
            },
            primary: {
                main: '#006aff',
            },
            secondary: {
                main: '#f0a200',
            },
        },
        typography: {
            fontFamily: ['"Nunito"', 'sans-serif'].join(','),
            fontSize: 13
        }
    });

    useEffect(() => {
        if (mode === 'dark') {
            darkMode()
        } else {
            lightMode()
        }
    }, [mode])

    return (
        <ThemeContext.Provider value={{ mode, setMode }}>
            <MuiThemeProvider theme={theme}>
                {children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
    )
}

function darkMode() {
    document.documentElement.style.setProperty('--fq-header-color', '#1d1d1d');
    document.documentElement.style.setProperty('--fq-border-color-1', 'rgba(255, 255, 255, 0.12)');
    document.documentElement.style.setProperty('--fq-warning-red', 'rgb(189, 32, 32)');
    document.documentElement.style.setProperty('--fq-warning-orange', '#b87616');
    document.documentElement.style.setProperty('--fq-text-placeholder-color', '#787878');
    document.documentElement.style.setProperty('--fq-fetch-note-input-background', '#2c2c2c');
    document.documentElement.style.setProperty('--fq-primary-text-color', '#fff');
    document.documentElement.style.setProperty('--fq-color-text-background', 'linear-gradient(to right, #75b0fd, #75b0fd)');
    document.documentElement.style.setProperty('--fq-disabled-color-button', '#8b8b8b');
    document.documentElement.style.setProperty('--fq-disabled-background-button', '#2c2c2c');
    document.documentElement.style.setProperty('--fq-disabled-border-button', '1px solid #585858');
}

function lightMode() {
    document.documentElement.style.setProperty('--fq-header-color', '#ffffffc2');
    document.documentElement.style.setProperty('--fq-border-color-1', 'rgba(255, 255, 255, 0.76)');
    document.documentElement.style.setProperty('--fq-border-color-1', 'rgba(0, 0, 0, 0.1)');
    document.documentElement.style.setProperty('--fq-warning-red', 'rgb(255, 40, 40)');
    document.documentElement.style.setProperty('--fq-warning-orange', '#f0a538');
    document.documentElement.style.setProperty('--fq-text-placeholder-color', 'rgba(31, 31, 31, 0.137)');
    document.documentElement.style.setProperty('--fq-fetch-note-input-background', 'var(--fq-background-color-1)');
    document.documentElement.style.setProperty('--fq-primary-text-color', '#132f4c');
    document.documentElement.style.setProperty('--fq-color-text-background', 'linear-gradient(to right, #006aff, #004a95)');
    document.documentElement.style.setProperty('--fq-disabled-color-button', '#a8a8a8');
    document.documentElement.style.setProperty('--fq-disabled-background-button', '#e8e8e8');
    document.documentElement.style.setProperty('--fq-disabled-border-button', '1px solid #bebebe');
}

interface IGlobalProvider {
    children: React.ReactElement
}
import axios from 'axios';



const axiosPublic = axios.create({
    withCredentials: true,
    baseURL: 'localhost: 4000'
})

const axiosPrivate = axios.create({
    withCredentials: true,
    baseURL: 'localhost: 4000'
})


export { axiosPublic, axiosPrivate }
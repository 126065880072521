let BASE_URL = ''
if (process.env.NODE_ENV === 'production') {
    BASE_URL = 'https://api.nithinpb.com';
} else {
    BASE_URL = "http://127.0.0.1:4000";
}
const PREFIX = 'v1'

function makeUrl(endUrl: string) {
    return `${BASE_URL}/api/${PREFIX}/${endUrl}/`
}

export const apiEndPoint = {
    SALESFORCE_USERNAME_PASSWORD: makeUrl('salesforce/auth'),
    SALESFORCE_OAUTH: makeUrl('salesforce/auth/oauth2'),
    SALESFORCE_OAUTH_TOKEN: makeUrl('salesforce/auth/oauth2/token'),
    SALESFORCE_REFRESH_TOKEN: makeUrl('salesforce/auth/refresh'),
    SALESFORCE_LOGOUT: makeUrl('salesforce/auth/logout'),
    SALESFORCE_LIST_OBJECTS: makeUrl('salesforce/listObjects'),
    SALESFORCE_DESCRIBE_OBJECT: makeUrl('salesforce/describeObject'),
    SALESFORCE_QUERY: makeUrl('salesforce/query'),
    SALESFORCE_GET_AUTH_USR: makeUrl('salesforce/identity'),
    CONTACT_FORM: makeUrl('miscellaneous/contact'),
    GET_NOTE: makeUrl('notepad/getNote'),
    WRITE_NOTE: makeUrl('notepad/writeNote')
}
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { TextField as MuiTextField, TextFieldProps } from '@mui/material';

const RedditTextField = styled((props: any) => (
    <MuiTextField {...props} />
))(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: 10,
    },
}));

export default function TextFieldAutocomplete(props: TextFieldProps) {
    return <RedditTextField
        size={'small'}
        {...props}
    />
}
import { BrowserRouter as Router } from "react-router-dom"
import { QueryClient, QueryClientProvider } from 'react-query'

import { ThemeProvider } from './theme-provider'
import { AuthorizationContext } from "../contexts"

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: 5 * 60 * 1000,
        },
    },
})






export default function GlobalProvider({ children }: IGlobalProvider) {
    const authorization = true

    return (
        <ThemeProvider>
            <QueryClientProvider client={queryClient}>
                <Router>
                    <AuthorizationContext.Provider value={{ authorization }}>
                        {children}
                    </AuthorizationContext.Provider>
                </Router>
            </QueryClientProvider>
        </ThemeProvider>
    )
}

interface IGlobalProvider {
    children: React.ReactElement
}
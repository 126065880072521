import React from "react";
import { NavLink } from 'react-router-dom';
import { IconButton, Stack, Typography } from "@mui/material";

//@ts-ignore
import Logo from "../../assets/images/logo_v2.svg";
import './index.scss'
import { DarkMode, LightMode } from "@mui/icons-material";
import { useTheme } from "../../hooks";

export default function NotepadHeader(props: any) {
    const { notepadId, warning }: { notepadId: string, warning: boolean } = { ...props }
    const { mode, setMode } = useTheme()

    const changeTheme = () => {
        setMode((current: any) => current === 'dark' ? 'light' : 'dark')
    }
    return (
        <header style={{ width: '100%', height: 56 }}>
            <Stack direction='row' alignItems={'center'} justifyContent={'space-between'}
                className="header-wrapper" >
                <nav>
                    <NavLink to="/notedrop" style={{ display: 'flex', padding: '13px 5px' }}>
                        <img style={{ width: 68 }} draggable={true} src={Logo} alt="nithinpb.com logo" />
                    </NavLink>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 16 }}>
                        <IconButton onClick={changeTheme} sx={{ border: '1px solid', borderRadius: 2, color: 'var(--fq-border-color-1)' }} size={'small'}>
                            {
                                mode === 'dark' ?
                                    <DarkMode fontSize={'small'} sx={{ color: 'var(--fq-primary-color)' }} /> :
                                    <LightMode fontSize={'small'} sx={{ color: 'var(--fq-primary-color)' }} />
                            }
                        </IconButton>
                    </div>
                    {
                        notepadId &&
                        <div style={{ display: 'flex', padding: '16px 16px', background: warning ? 'var(--fq-warning-red)' : 'var(--fq-warning-orange)', color: '#fff', transition: '0.3s' }} className={warning ? "blink_me" : ""}>
                            <Typography>
                                {notepadId}
                            </Typography>
                        </div>
                    }
                </nav>
                <div>

                </div>
            </Stack>
        </header>
    )
}
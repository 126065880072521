import React from 'react';
import {Autocomplete as MuiAutocomplete, Paper, styled} from '@mui/material';


const CustomAutocomplete = styled((props: any) => (
    <MuiAutocomplete
        {...props}
    />
))(({theme}) => ({
    '& .MuiFilledInput-root': {
        border: '1px solid #e2e2e1',
        overflow: 'auto',
        borderRadius: 10,
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            backgroundColor: 'transparent',
            borderBottomColor: '#e2e2e1'
        },
        '&.Mui-focused': {
            backgroundColor: 'transparent',
            //boxShadow: `${alpha(theme.palette.primary.main, 1)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
        '&:before, &:hover:not(.Mui-disabled):before': {
            borderBottomColor: 'transparent'
        }
    }
}));

export default function AutoComplete(props: any) {
    return (
        <CustomAutocomplete
            size={'small'}
            {...props}
            PaperComponent={AutoCompletePaper}
        />
    )
}


function AutoCompletePaper(props: any) {
    return (
        <Paper {...props} sx={{borderRadius: 3, mt: 1, boxShadow: 'var(--fq-box-shadow-2)', zIndex: 10000}}/>
    )
}
import React from 'react'
import { Paper as MuiPaper, styled, PaperProps } from '@mui/material'


const CustomPaper = styled(MuiPaper)(() => ({
    borderRadius: 10,
    overflow: 'hidden'
}));


export default function Paper(props: PaperProps) {
    return (
        <CustomPaper variant="outlined"  {...props} />
    )
}
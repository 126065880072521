import { useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DocumentScanner, DownloadOutlined } from "@mui/icons-material";

import './index.scss'
import { NotepadHeader } from "../../components";

export default function NotepadHome() {
    const navigate = useNavigate()
    const titleFontProperties = { fontWeight: 600, pb: 1 }


    const handleClick = () => {
        const uniqueString = generateUniqueString();
        navigate(`/notedrop/${uniqueString}`, { state: { newPage: true } })
    }

    function generateUniqueString() {
        const timestamp = Date.now().toString(36);
        const random = Math.random().toString(36).substr(2, 5);

        const uniqueString = timestamp + random;
        let machineIdentifier = ''
        for (let i = 0; i < uniqueString.length; i++) {
            if (i > 0 && i % 7 === 0) {
                machineIdentifier += '-';
            }
            machineIdentifier += uniqueString[i];
        }
        return machineIdentifier;
    }

    return (
        <div className={'Notepad-Container h-full'}>
            <NotepadHeader />
            <div className={'notepad-home'} >
                <Grid container justifyItems={'center'} alignItems={'center'} spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Typography
                            sx={{ ...titleFontProperties, mb: -1, mt: 3 }}
                            variant={'h3'}
                            color={'primary'}
                            style={{ fontSize: 'clamp(3rem, 1.2857rem + 3.5714vw, 4rem)', letterSpacing: "-0.1rem", marginLeft: 'clamp(-6px, -6px, -6px)', fontWeight: 700 }}
                            className={'colored-text title-text-width-limit'}>
                            NoteDrop
                        </Typography>
                        <Typography sx={titleFontProperties}
                            variant={'h3'}
                            color={'text.primary'}
                            style={{ fontSize: 'clamp(2.2rem, 1.157rem + 3.1vw, 3.5rem)', letterSpacing: "-0.1rem", marginLeft: 'clamp(-6px, -6px, -6px)' }}>
                            Streamline, Capture, Conquer
                        </Typography>
                        <Typography
                            sx={{ mb: 2, mt: 2 }}
                            style={{ fontSize: '1rem' }}
                            variant={'body1'}
                            color={'text.primary'}
                            className={'title-text-width-limit'}
                            gutterBottom>
                            NoteDrop: Boost Your Efficiency with Simplified Note-Taking and Clipboard Mastery. Seamlessly jot down important thoughts, ideas, and tasks while effortlessly managing your clipboard content. Stay organized, focused, and in control of your productivity with NoteDrop.
                        </Typography>
                        <Grid
                            container
                            className={'button-wrapper'}
                            sx={{ mt: 4 }}
                            alignItems={'center'}>
                            <Grid item xs={12} sm={12}>
                                <button className={'main-button'}
                                    onClick={handleClick}
                                    style={{ background: 'linear-gradient(45deg, #3867fc, #2a57c5)', color: '#fff', border: 'none', padding: '16px 24px' }}>
                                    <Stack direction="row" alignItems={'center'} spacing={1}>
                                        <DocumentScanner fontSize={'medium'} />
                                        <Typography variant='button' sx={{ textTransform: 'none' }}>
                                            Create a Note
                                        </Typography>
                                    </Stack>
                                </button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6} mb={2} className={'h-full'}>
                        <RetrieveCode />
                    </Grid>
                </Grid >
            </div>
        </div>
    )
}


function RetrieveCode() {
    const [code, setCode] = useState('')
    const navigate = useNavigate()

    const handleClick = () => {
        navigate(`/notedrop/${code}?fetch=true`)
    }

    return (
        <Box className={'retrieve-note'}>
            <Typography variant={'h6'} gutterBottom color={'text.secondary'}>
                Enter NoteDrop Code
            </Typography>
            <input placeholder="livwylz-1c0x08" className="clip-memo-input" value={code} onChange={(e: any) => setCode(e.target.value)} />
            <button className={'main-button retrieve-button'}
                disabled={!code}
                onClick={handleClick}
                style={{ padding: '16px 24px', paddingRight: 32 }}>
                <Stack direction="row" alignItems={'center'} spacing={1}>
                    <DownloadOutlined fontSize={'medium'} />
                    <Typography variant='button' sx={{ textTransform: 'none' }}>
                        Retrieve Memorized Note
                    </Typography>
                </Stack>
            </button>
        </Box>
    )
}
import { Route, Routes } from "react-router-dom";
import { NotFound, NotepadHome, Notepad } from '../pages'

import { NotepadLayout } from "../layouts";

export default function RootRoute() {
    return (
        <Routes>
            <Route path='/' element={<NotepadLayout />} >
                <Route path='notedrop' element={<NotepadHome />} />
                <Route path='/notedrop/:id' element={<Notepad />} />
            </Route>
            <Route path='*' element={<NotFound />} />
        </Routes>
    )
}

import { Typography } from "@mui/material"
import { Button } from "../../shared"

export function PasswordFirewall(props: any) {
    return (
        <Backdrop open={true}>
            <div>
                <Input {...props} />
                <Button variant={'contained'}
                    sx={{ borderRadius: 2, mt: 2 }}
                    onClick={props.onFetch}
                    size={'large'}
                    disabled={!props.value}>
                    Fetch note
                </Button>
            </div>
        </Backdrop>
    )
}


function Backdrop(props: any) {
    return (
        <div style={{
            background: 'var(--fq-primary-text-color)',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 15000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh'
        }}>
            {props.children}
        </div>
    )
}

function Input(props: any) {
    return (
        <div>
            <Typography sx={{ color: '#fff' }} gutterBottom>
                Note password
            </Typography>
            <input
                value={props.value}
                onChange={props.onChange}
                style={{
                    borderRadius: 10,
                    border: '1px solid #fff',
                    outline: 'none',
                    fontSize: 16,
                    fontWeight: 600,
                    padding: '10px 14px',
                    fontFamily: 'var(--fq-font-family)',
                    width: 300
                }} />
        </div>
    )
}
import MonacoEditor, { useMonaco } from '@monaco-editor/react';
import { useEffect, useState, useRef } from 'react';
import { Box, Typography } from '@mui/material';

import { useTheme } from '../../hooks';
import { IEditor } from '../../interfaces';
import { defineTheme, nithinpbdotcom, initialOptions } from './variables'
import './index.scss'


export default function Editor(props: IEditor) {
    const { value, onValueChange, readOnly, language, editorRef } = { ...props }
    const [options, setOptions] = useState(initialOptions)
    const { mode } = useTheme()
    const monacoInstance = useMonaco()

    const handleOnMount = (editor: any, monaco: any) => {
        editorRef.current = editor;
        if (monacoInstance) {
            monacoInstance.editor.setTheme(nithinpbdotcom.name)
        }

    }

    useEffect(() => {
        if (monacoInstance) {
            defineTheme(monacoInstance, mode)
            monacoInstance.editor.setTheme(nithinpbdotcom.name)
        }

        document.fonts.ready.then(() => {
            monacoInstance?.editor.remeasureFonts()
        })
    }, [monacoInstance, mode])

    return (
        <div className='h-full Editor-Container'>
            <MonacoEditor
                onMount={handleOnMount}
                options={options}
                height={'100%'}
                onChange={(value: any) => onValueChange(value)}
                loading={<NoteLoading />}
                theme={nithinpbdotcom.name}
                language={language}
                value={value}
                defaultLanguage={'plaintext'}
                defaultValue={value} />
        </div>
    )
}


function NoteLoading() {
    return (
        <Box>
            <Typography>
                Note preparing...
            </Typography>
        </Box>
    )
}
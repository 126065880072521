import * as React from 'react';
import { styled } from '@mui/material/styles';
import { TextField as MuiTextField, TextFieldProps } from '@mui/material';

const RedditTextField = styled((props: any) => (
  <MuiTextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 10,
    backgroundColor: theme.palette.mode === 'light' ? 'transparent' : 'transparent',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .Mui-disabled': {
      'backgroundColor': '#f1f1f1'
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `${theme.palette.primary.main} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function TextField(props: TextFieldProps) {
  return <RedditTextField
    variant="filled"
    size={'small'}
    {...props}
  />
}